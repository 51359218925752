import React from "react"
import CMSRender from "aldoo-ra/CMS/cms-render"

const HeaderSectionRight = ({ data }) => {
  return (
    <div className="w-full md:w-[40%] xl:w-[60%] 2xl:w-1/2 xl:h-[647px] 2xl:h-[1000px] flex flex-col items-center md:items-start xl:items-end md:justify-end xl:justify-center 2xl:justify-start px-6 md:px-0 mx-auto">
      <div className="w-full max-w-[420px] md:max-w-[350px] lg:max-w-[450px] xl:max-w-[600px] 2xl:max-w-[700px] xl:max-h-[647px] 2xl:h-[1000px] aspect-662/940 xl:pt-6 2xl:pt-5 lg:mt-14 2xl:mt-0">
        <img src={data.images[0]} alt="Android Device" className="w-full h-auto" />
      </div>
    </div>
  )
}

export default HeaderSectionRight
