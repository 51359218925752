import React from "react"
import Typography from "aldoo-ra/Typography"
import Button from "aldoo-ra/Button"
import StatisticsCardsShort from "../../StatisticsCardsShort.jsx"
import parse from "html-react-parser"

const HeaderSectionLeft = ({ data }) => {
  return (
    // Same classes assigned for both platforms to ensure same layout
    <div className="flex flex-col w-full md:w-[60%] xl:w-[680px] 2xl:w-1/2 xl:flex-shrink-0 justify-start items-start">
      <div className="flex flex-col w-full max-w-[800px] items-center md:items-start lg:pt-[64px] 2xl:pt-[120px]">
        {/* Title */}
        <Typography
          component="h1"
          variant="title2"
          className="w-full text-center md:text-left mb-2 md:mb-4"
        >
          {parse(data.title)}
        </Typography>

        {/* Subtitle: wrapped in a div with equal height for both platforms, to ensure same positioning of the buttons below when the text goes in 2 lines */}
        <div className="w-full max-w-[300px] md:max-w-[400px] lg:max-w-full md:h-[100px] lg:h-[85px] xl:h-[85px] xl:mb-5">
          <Typography
            variant="subtitle_signature"
            className="text-center md:text-left"
          >
            {data.description}
          </Typography>
        </div>

        {/* CTA Buttons and Statistics */}
        <div className="flex flex-col-reverse md:flex-col items-start gap-6 md:gap-10 lg:gap-[56px] mt-6 md:mt-0">
          <div className="flex items-center gap-6">
            {data.menu?.map((item, index) => {
              item.enabled
              if ( !item.enabled ) return
              const variant = index === 0 ? "primary_accent_xl" : "secondary_xl"
              const url = item?.link && item?.link[0].url

              return (
                <Button key={index} variant={variant}>
                  <a href={url} target="_blank">
                    {item?.label}
                  </a>
                </Button>
              )
            })}
          </div>

          {/* Statistics Cards - Android Only */}
          <StatisticsCardsShort />
        </div>
      </div>
    </div>
  )
}

export default HeaderSectionLeft
